import React from 'react';
import { Image, StyleSheet, Text, View, Page } from '@react-pdf/renderer';
import { FormattedMessage } from 'react-intl';
import { colors, fontSizes } from '../Common';
import frayLogo from '../../../FileTemplatesAndImages/f-ray.png';
import tamedLogo from '../../../FileTemplatesAndImages/ta3meed-logo.png';
import { months2 } from '../../../Util/Constants';
import { useCompanyCode } from '../context/CompanyCodeProvider';

export const PdfFirstPage = ({ customer, documentName }) => {
  const { companyCode } = useCompanyCode();
  const styles = StyleSheet.create({
    dash: {
      height: '100%',
      width: '100%',
      backgroundColor: colors.primary,
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    logo: {
      height: companyCode === 'ta3meed' ? '90px' : '73px',
      width: '266px',
    },
  });
  const date =
    (customer && customer.createdAt && new Date(customer.createdAt)) || '';
  const year = date && date.getFullYear();
  const month = date && date.getMonth();
  const day = date && date.getDate();
  const logo = companyCode === 'ta3meed' ? tamedLogo : frayLogo;
  const fullName = customer && customer.customer && customer.customer.fullName;
  return (
    <Page style={{ fontFamily: 'Open Sans' }}>
      <View style={styles.dash}>
        <Image src={logo} style={styles.logo} />
        <View
          style={{
            marginTop: '73px',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <View
            style={{
              height: '5px',
              backgroundColor: colors.secondary,
              width: '100%',
            }}
          ></View>
          <View
            style={{
              width: '200px',
              height: '25px',
              backgroundColor: colors.secondary,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '-15px',
            }}
          >
            <Text
              style={{
                color: colors.white,

                fontWeight: 600,
                fontSize: fontSizes.title,
              }}
            >
              {documentName ? (
                <FormattedMessage id={documentName} />
              ) : companyCode === 'ta3meed' ? (
                'Qualitative Analysis'
              ) : (
                '-'
              )}
            </Text>
          </View>
        </View>

        <View
          style={{
            marginTop: '73px',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {fullName && (
            <Text
              style={{
                color: colors.white,
                marginBottom: '10px',
              }}
            >
              {fullName}
            </Text>
          )}
          {date && (
            <Text
              style={{
                color: colors.white,
              }}
            >
              {day} <FormattedMessage id={months2[month]} /> {year}
            </Text>
          )}
        </View>
      </View>
    </Page>
  );
};
