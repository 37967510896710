import React, { useState } from 'react';
import { Table } from 'reactstrap';
import FirmLoadingAlert from '../Components/Alerts';
import { Tab, Tabs } from 'react-bootstrap';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { ExcelImportFromTable } from '../Components/DownloadExcelFromTable';
import { specifyFontWeight, specifyColor } from '../Util/Constants';

export function DetayliFinansalTablolar({ value, slug, currencyInfo }) {
  const [key4, setKey] = useState(slug + 0);
  const intl = useIntl();

  if (!value) return <FirmLoadingAlert />;

  const excelProps = value.map((e, i) => {
    return { id: `detayli${i}-to-xls`, sheetName: e.name };
  });

  const filteredTitles = value.map((row) => {
    return {
      name: row.name,
      dataList: row.dataList.map((data) => {
        return {
          mainEntry: data.mainEntry,
          titles: data.titles.filter((title) => {
            return (
              title.values[0]?.value !== 0 ||
              title.values[1]?.value !== 0 ||
              (title.values[2] && title.values[2].value !== 0) ||
              (title.values[3] && title.values[3].value !== 0)
            );
          }),
        };
      }),
    };
  });

  return (
    <div className={'mt-2'}>
      <div style={{ justifyContent: 'space-between', display: 'flex' }}>
        <div>
          {currencyInfo && (
            <>
              {' '}
              <div style={{ fontWeight: 'bold' }}>
                *{currencyInfo.currencyText}
              </div>
              <div style={{ fontWeight: 'bold' }}>
                {<FormattedMessage id="CURRENCY_UNIT" />}:{' '}
                {currencyInfo.currencyUnit}
              </div>
            </>
          )}
        </div>
        <button
          onClick={() => ExcelImportFromTable(excelProps, intl)}
          className="font-xl btn btn-primary btn-block"
          type="button"
        >
          <FormattedMessage id="DETAILLED_FINACIAL_TABLE" />{' '}
          <FormattedMessage id="DOWNLOAD" />
        </button>
      </div>
      <Tabs activeKey={key4} onSelect={(key4) => setKey(key4)}>
        {filteredTitles.map((row, i) => (
          <Tab eventKey={slug + i} title={row.name} key={i}>
            <Table id={'detayli' + i + '-to-xls'} responsive bordered size="sm">
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="SUBTAB_4_1_TITLE1" />
                  </th>
                  {row.dataList &&
                    row.dataList[0] &&
                    row.dataList[0].titles &&
                    row.dataList[0].titles[0] &&
                    row.dataList[0].titles[0].values.map((a, i) => (
                      <React.Fragment key={i}>
                        <th>
                          {a.year.endsWith('/0') || a.year.includes('/0 vs')
                            ? a.year.replace('/0', '')
                            : a.year}
                        </th>
                      </React.Fragment>
                    ))}
                </tr>
              </thead>
              <tbody>
                {row.dataList &&
                  row.dataList[0] &&
                  row.dataList[0].titles &&
                  row.dataList.map((b, i) => (
                    <React.Fragment key={i}>
                      {b.mainEntry && (
                        <tr>
                          <td
                            colSpan={12}
                            style={{
                              background: '#a4b5c0',
                            }}
                          >
                            <p
                              style={{
                                textAlign: 'center',
                                padding: 0,
                                margin: 10,
                                fontWeight: 'bold',
                              }}
                            >
                              {b.mainEntry}
                            </p>
                          </td>
                        </tr>
                      )}

                      {b.titles
                        .filter(
                          (r) =>
                            r.values[0]?.value !== 0 ||
                            r.values[1]?.value !== 0 ||
                            (r.values[2] && r.values[2].value !== 0) ||
                            (r.values[3] && r.values[3].value !== 0)
                        )
                        .map(
                          (a, j) =>
                            a.title && (
                              <tr
                                key={j}
                                style={{
                                  background: specifyColor(a, b.titles[j + 1]),
                                }}
                              >
                                <td>
                                  <p
                                    style={{
                                      fontWeight: specifyFontWeight(
                                        a,
                                        b.titles[j + 1]
                                      ),
                                    }}
                                  >
                                    {a.title}
                                  </p>
                                </td>
                                {a.values.map((c, index) => (
                                  <React.Fragment key={index}>
                                    <td>
                                      {c.year.toString().indexOf('vs') === -1 &&
                                      c.year.toString().indexOf('Dikey') === -1
                                        ? ''
                                        : c.value === 0
                                          ? ''
                                          : '%'}
                                      {c.year.toString().indexOf('vs') === -1 &&
                                      c.year.toString().indexOf('Dikey') ===
                                        -1 ? (
                                        c.value === 0 ? (
                                          '0'
                                        ) : (
                                          <FormattedNumber value={c.value} />
                                        )
                                      ) : c.value === 0 ? (
                                        '0'
                                      ) : (
                                        <FormattedNumber
                                          value={c.value * 100}
                                        />
                                      )}
                                    </td>
                                  </React.Fragment>
                                ))}
                              </tr>
                            )
                        )}
                    </React.Fragment>
                  ))}
              </tbody>
            </Table>
          </Tab>
        ))}
      </Tabs>
    </div>
  );
}
