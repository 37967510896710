import React from 'react';
import { Input, Label } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { Button_ } from '../ActionButtons/ActionButtons';

const visibleColumnCount = (visibleColumns) => {
  return visibleColumns.filter((column) => column.visible).length;
};

export const RadioButtonGroups = (props) => {
  const { visibleColumns, setVisibleColumns } = props;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: '5px',
      }}
    >
      <Label>
        <FormattedMessage id="PERIODS" />:
      </Label>
      {visibleColumns.map((column, index) => (
        <div key={index}>
          <Button_
            onClick={(e) => {
              e.preventDefault();
              if (visibleColumnCount(visibleColumns) > 1 || !column.visible) {
                const newState = [...visibleColumns];
                newState.map((col) =>
                  col.year === column.year
                    ? (col.visible = !col.visible)
                    : col.visible
                );

                setVisibleColumns(newState);
              }
            }}
            id={column.year}
            text={column.year}
            style={{
              marginLeft: '3px',
              backgroundColor: column.visible
                ? 'var(--bs-gray-700)'
                : 'var(--bs-gray-500)',
              padding: '1px 2px',
            }}
          />
        </div>
      ))}
    </div>
  );
};
