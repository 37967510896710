import React, { useState, useEffect, useRef } from 'react';
import { Breadcrumb } from 'react-admin-base-nazox';
import { Card, CardBody } from 'reactstrap';
import { Tab, Tabs } from 'react-bootstrap';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import axios from 'axios';
import { CustomerInfo } from './CustomerInfo';
import { CustomerAnalysisTable } from '../../Components/tables/customer-profile/CustomerAnalysisTable';
import { CustomerFinancialsContent } from '../../Components/customer-profile/CustomerFinancialsContent';
import { CustomerFilesContent } from '../../Components/customer-profile/CustomerFileContent';
import { CustomersTable_ } from '../../Components/tables/customer-profile/CustomersTable_';
import { useAuth } from 'react-admin-base';
import { Alerts } from '../../Components/Alerts';
import { NonFinancial } from '../NonFinancial/NonFinancial';
import NewsFeed from '../NewsFeed/NewsFeed';

export const CustomerProfile = () => {
  const [API] = useAuth();
  const { id } = useParams();
  const [customer, setCustomer] = useState({});
  const [customerLoading, setCustomerLoading] = useState(true);
  const [newsFeedQueryId, setNewsFeedQueryId] = useState('');
  const [noDataAvailable, setNoDataAvailable] = useState(false);
  const [delayedNonFinancial, setDelayedNonFinancial] = useState(false);
  const [customerData, setCustomerData] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();

  {
    /*const retrySubmitNewsFeedQuery = async (
    customerData,
    retries = 5,
    delay = 2000
  ) => {
    let attempts = 0;
    while (attempts < retries) {
      try {
        await new Promise((resolve) => setTimeout(resolve, 10000));

        const response = await axios.post(
          'http://localhost:2019/newsFeed/submitQueryForNewsFeed',
          {
            tax_no: customerData.taxNumber,
            customerId: customerData.id || '',
            city: customerData.cityName || '',
            tradeRegistrationNumber: customerData.tradeRegistrationNumber || '',
            name: customerData.name || '',
            recordDate: customerData.recordDate || '',
            number: customerData.number || '',
            page: customerData.page || '',
            advType: customerData.advType || '',
            gazetteLink: customerData.gazetteLink || '',
          }
        );

        if (response.status === 200) {
          return { message: response.data, error: false };
        } else if (response.status === 400) {
          return { message: '', error: true };
        }
      } catch (error) {
        if (error.response && error.response.status === 500) {
          console.error(`Attempt ${attempts + 1} failed with 500. Retrying...`);
        } else {
          console.error('An error occurred:', error);
          throw new Error(
            'Failed to submit NewsFeed query due to an error: ' + error.message
          );
        }
      }
      attempts += 1;
      await new Promise((resolve) => setTimeout(resolve, delay));
    }
    throw new Error('Failed to submit NewsFeed query after maximum retries.');
  }; 
  useEffect(() => {
    const previousPage = location.state?.from || '';

    if (previousPage === '/customer/add') {
      setTimeout(() => {
        setDelayedNonFinancial(true);
      }, 75000);
    } else {
      setDelayedNonFinancial(true);
    }

    setTimeout(() => {
      setCustomerLoading(false);
    }, 2000);
  }, [id]);
*/
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: customerData } = await API.tokenized.get(
          `/customer/get/${id}`
        );
        console.log('cus', customerData);
        setCustomer(
          customerData.phoneNumber && customerData.taxNumber
            ? {
                ...customerData,
                phoneNumber: customerData.phoneNumber
                  .toString()
                  .replace('+', ''),
                number: customerData.number.toString().replace('+', ''),
                sector: customerData.sector?.code
                  ? { ...customerData.sector }
                  : null,
                groupId: {
                  label: customerData.groupName,
                  value: customerData.groupId,
                },
              }
            : {
                ...customerData,
                sector: customerData.sector?.code
                  ? { ...customerData.sector }
                  : null,
                groupId: {
                  label: customerData.groupName,
                  value: customerData.groupId,
                },
              }
        );

        /*setTimeout(async () => {
          const { message: newsFeedResponseMessage, error: newsFeedError } =
            await retrySubmitNewsFeedQuery(customerData);
          if (newsFeedError) {
            console.log('newsFeedError');
          } else {
            setNewsFeedQueryId(newsFeedResponseMessage);
          }
        }, 0); */
      } catch (error) {
        console.error('Failed to fetch customer or submit query:', error);
      } finally {
        setCustomerLoading(false);
      }
    };
    if (id) fetchData();
  }, [id]);

  return (
    <Breadcrumb
      title={'Müşteri Profil'}
      data={[
        {
          href: '/customers',
          name: <FormattedMessage id="CUSTOMERS" />,
        },
        {
          href: `/customer/${id}/profile`,
          name: <FormattedMessage id="CUSTOMER_PROFILE" />,
        },
      ]}
    >
      {customerLoading ? (
        <Alerts
          color="primary"
          className="fas fa-spin fa-spinner"
          messageId="LOADING"
          label=""
        />
      ) : (
        <Card>
          <CardBody>
            {customer && <CustomerInfo customer={customer} />}
            <hr />
            <div>
              {customer && (
                <Tabs
                  id="controlled-tab-example0"
                  className="mb-3"
                  unmountOnExit={true}
                  mountOnEnter={true}
                  transition={false}
                >
                  {customer.isGroupCompany && (
                    <Tab
                      eventKey="customers"
                      title={<FormattedMessage id="GROUP_FIRMS" />}
                    >
                      <CustomersTable_ groupId={customer.id} />
                    </Tab>
                  )}
                  {!customer.isGroupCompany && (
                    <Tab
                      eventKey="customer-analysis"
                      title={<FormattedMessage id="CUSTOMER_ANALYSIS" />}
                    >
                      <CustomerAnalysisTable id={id} />
                    </Tab>
                  )}
                  {!customer.isGroupCompany && (
                    <Tab
                      eventKey="documents"
                      title={<FormattedMessage id="FILES" />}
                    >
                      <CustomerFilesContent id={id} />
                    </Tab>
                  )}
                  {!customer.isGroupCompany && (
                    <Tab
                      eventKey="financials"
                      title={<FormattedMessage id="FINANCIALS" />}
                    >
                      <CustomerFinancialsContent customerId={id} />
                    </Tab>
                  )}
                  {/*
                  {!customer.isGroupCompany && (
                    <Tab
                      eventKey="non-financial"
                      title={'Finansal Olmayan Analiz '}
                    >
                        {delayedNonFinancial ? (  
                      <NonFinancial
                        customerId={id}
                        noDataAvailable={noDataAvailable}
                      />
                         ) : (
                        <Alerts
                          color="primary"
                          className="fas fa-spin fa-spinner"
                          messageId="   Yeni Müşteri verileri yüklenirken lütfen bekleyiniz. Bu işlem yaklaşık birkaç dakika sürecektir. Sayfayı kapatmayın! "
                          label=""
                        />
                      )} 
                    </Tab>
                  )} */}
                  {/* {!customer.isGroupCompany && (
                    <Tab eventKey="news-feed" title={'Haber Akışı '}>
                      <NewsFeed queryId={newsFeedQueryId} />
                    </Tab>
                  )}  */}
                </Tabs>
              )}
            </div>
          </CardBody>
        </Card>
      )}
    </Breadcrumb>
  );
};
