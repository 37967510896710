import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Input,
  Label,
} from 'reactstrap';
import { Button_ } from './ActionButtons/ActionButtons';
import { evaluate, mod } from 'mathjs';
import Swal from 'sweetalert2';
import { findChangedIndexAndCollapsibleInfo } from '../pages/Analysis/AktifAnalysisEdit';
import NumberFormat from 'react-number-format';

// This is a modal component that is used to display a modal with a text area and a button to save the text area content.

// Geçerli karakterler: sayılar, +, -, *, /, ve opsiyonel olarak bir virgül
const regex = /^[\d+\-*/,]*$/;

const getDefaultState = (
  years,
  calculatorDatas,
  modalExpression,
  userId,
  documentId,
  uniqueRowName
) => {
  return years.map((year) => {
    const expression = modalExpression.find(
      (m) => Number(m.expressionYear) === year
    );
    const expressionInfo = expression
      ? expression
      : {
          uniqueRowName: uniqueRowName,
          expressionYear: year,
          expressionText: '',
          userId: userId,
          documentId: documentId,
        };
    return {
      year: year,
      result: calculatorDatas[year] || 0,
      expressionInfo: expressionInfo,
    };
  });
};

export const NoteAndValueModal = (props) => {
  const {
    isOpen,
    toggle,
    data,
    notes,
    setNotes,
    optionsState,
    calculatorDatas,
    years,
    changeCalculatorData,
    name,
    statement,
    setStatement,
    _statement,
    setModalExpressions,
    modalExpressions,
    userId,
    documentId,
  } = props;

  const { commentText, uniqueRowName } = data;
  const calculatedData = calculatorDatas[uniqueRowName];
  const modalExpression = modalExpressions.filter(
    (m) => m.uniqueRowName === uniqueRowName
  );
  const [state, setState] = React.useState(
    getDefaultState(
      years,
      calculatedData,
      modalExpression,
      userId,
      documentId,
      uniqueRowName
    )
  );
  const [textValue, setTextValue] = React.useState(commentText || '');
  const [isCalculateButtonClicked, setIsCalculateButtonClicked] =
    React.useState(false);

  const calculateResult = () => {
    try {
      const newState = [...state];
      let modalExpression_ = [...modalExpression];

      newState.forEach((item, index) => {
        if (
          !item.expressionInfo.expressionText ||
          typeof item.expressionInfo.expressionText !== 'string'
        )
          return;

        // Virgülü noktaya çevir ve sonucu hesapla
        const sanitizedExpression = item.expressionInfo.expressionText
          ? item.expressionInfo.expressionText.replaceAll(',', '.')
          : '';

        const result = evaluate(sanitizedExpression);

        newState[index].result = result || 0;
        newState[index].expressionInfo.expressionText =
          newState[index].expressionInfo.expressionText === '0'
            ? ''
            : newState[index].expressionInfo.expressionText;

        const expIndex = modalExpression_.findIndex(
          (m) => m.expressionYear === item.year
        );
        console.log('modalExpressions_: ', modalExpression_);

        if (expIndex !== -1) {
          modalExpression_[expIndex].expressionText =
            item.expressionInfo.expressionText;
        } else {
          modalExpression_.push({
            uniqueRowName: uniqueRowName,
            expressionYear: item.year,
            expressionText: item.expressionInfo.expressionText,
            userId: userId,
            documentId: documentId,
          });
        }
      });

      setModalExpressions((prev) => {
        const filteredPrev = prev.filter(
          (m) => m.uniqueRowName !== uniqueRowName
        );
        return [...filteredPrev, ...modalExpression_];
      });
      setState(newState);
    } catch (error) {
      alert('Geçersiz ifade!');
    }
  };
  const handleSaveButton = () => {
    !isCalculateButtonClicked && calculateResult();
    const newStatement = JSON.parse(JSON.stringify(statement));
    console.log('state: ', state);
    state.forEach((item) => {
      const value = item.result ? item.result.toString() : '';
      console.log('item.result: ', item.result);

      changeCalculatorData(
        name,
        uniqueRowName,
        item.year,
        value ? value.replace('.', ',') : '',
        true
      );
      const newValue = value === '-' || value === ',' ? value : +value;

      const { nameIndex, titleIndex, yearIndex } =
        findChangedIndexAndCollapsibleInfo(
          name,
          uniqueRowName,
          item.year,
          statement
        );

      const value2 =
        _statement[nameIndex].data.rowList[titleIndex].values[yearIndex].value +
        newValue;

      newStatement[nameIndex].data.rowList[titleIndex].values[yearIndex].value =
        value2;
    });

    setStatement(newStatement);
    const newNotes = [...notes];

    if (textValue) {
      if (newNotes.length === 0) {
        newNotes.push({ ...data, ['commentText']: textValue });
      } else {
        const index = newNotes.findIndex(
          (n) => n.uniqueRowName === data.uniqueRowName
        );
        if (index === -1) {
          newNotes.push({ ...data, ['commentText']: textValue });
        } else newNotes[index].commentText = textValue;
      }
    } else {
      const index = newNotes.findIndex(
        (n) => n.uniqueRowName === data.uniqueRowName
      );
      if (index !== -1) {
        newNotes.splice(index, 1);
      }
    }
    setNotes(newNotes);

    Swal.fire({
      icon: 'success',
      customClass: 'swal-class',
      background: '#90EE90',
      timer: 1000,
      showConfirmButton: false,
      backdrop: false,
    });

    toggle();
  };

  const handleChange = (e) => {
    const value = e.target.value;
    const index = state.findIndex((item) => item.year === Number(e.target.id));

    const newState = [...state];
    newState[index].expressionInfo.expressionText = value;

    setState(newState);
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      style={{
        width: 'auto',
        maxWidth: '50%',
        maxHeight: '100vh',
        overflowY: 'auto',
      }}
    >
      <ModalHeader toggle={() => toggle()} />
      <ModalBody>
        <Row>
          <Col>
            <Label
              style={{
                width: '100%',
              }}
            >
              Açıklama
              <Input
                id="exampleText"
                name="text"
                type="textarea"
                value={textValue}
                onChange={(e) => {
                  setIsCalculateButtonClicked(false);
                  setTextValue(e.target.value);
                }}
              />
              <span
                style={{
                  fontSize: '0.8rem',
                }}
              >
                Minimum 10 karakter olmalıdır.{' '}
              </span>
            </Label>
          </Col>
        </Row>
        <Row className="mt-2">
          {state.map((item, index) => (
            <Col key={index}>
              <Label
                style={{
                  width: '100%',
                }}
              >
                {item.year}
                <Input
                  id={item.year}
                  name="text"
                  type="textarea"
                  value={item.expressionInfo.expressionText || ''}
                  onChange={(e) =>
                    regex.test(e.target.value) && handleChange(e)
                  }
                  style={{
                    height: '30px',
                  }}
                  // onFocus={() => {
                  //   const inputValue =
                  //     item.expressionInfo.expressionText.toString();
                  //   // Eğer değer 0'sa ve odaklanıldığında input'u temizle
                  //   if (inputValue === '0') {
                  //     const newState = [...state];
                  //     newState[index].expressionInfo.expressionText = '';
                  //     setState(newState);
                  //   }
                  // }}
                  // onBlur={() => {
                  //   const inputValue =
                  //     item.expressionInfo.expressionText.toString();
                  //   if (inputValue === '') {
                  //     const newState = [...state];
                  //     newState[index].expressionInfo.expressionText = 0;
                  //     setState(newState);
                  //   }
                  // }}
                />
              </Label>
              <span>
                Değer:{' '}
                {new Intl.NumberFormat('tr-TR', {}).format(
                  Number(item.result)
                ) || 0}
              </span>
            </Col>
          ))}
        </Row>
        <Row className="mt-2">
          <Col
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: '1rem',
            }}
          >
            <Button_
              text="Hesapla"
              color="primary"
              onClick={() => {
                calculateResult();
                setIsCalculateButtonClicked(true);
              }}
              buttonStyle={{
                display: 'flex',
                justifyContent: 'center',
              }}
            />

            <Button_
              text="Kaydet"
              color="success"
              buttonStyle={{
                display: 'flex',
                justifyContent: 'center',
              }}
              onClick={handleSaveButton}
              disabled={textValue && textValue.length < 10 ? true : false}
            />
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};
